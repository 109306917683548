@import "./StyleConstants.scss";

.NavBar__page {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    pointer-events: none;
    z-index: 2;
}

.NavBar__root {
    width: 100%;
    height: $bar_height;
    pointer-events: all;

    position: fixed;
    left: 0;
    top: 0;

    background-color: #fff;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    -webkit-box-shadow: 0px 12px 50px 7px rgba(34, 60, 80, 0.2); 
    -moz-box-shadow: 0px 12px 50px 7px rgba(34, 60, 80, 0.2); 
    box-shadow: 0px 12px 50px 7px rgba(34, 60, 80, 0.2);

    ._pc_menu_c {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .logo {
            color: #fff;
            font-size: 35px;
            height: $logo_height;
            margin-left: ($bar_height - $logo_height) / 2 + 8px;
            cursor: pointer;
        }

        .logo_img {
            height: 100%;
            object-fit: contain;
        }

        ._menu_wrap {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            width: max-content;

            ._menu_link {
                color: #616161;
                text-decoration: none;
                font-size: 20px;
                font-weight: 600;
                margin-right: 32px;
                font-style: italic !important;
            }

            ._menu_link_lang {
                width: max-content;
                height: 25px;
                object-fit: contain;
                cursor: pointer;
                margin-right: 32px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
            }

            ._menu_link_lang_img {
                height: 100%;
                object-fit: contain;
                margin-right: 8px;
            }

            ._menu_link_lang_alias {
                color: #616161;
                text-decoration: none;
                font-size: 20px;
                font-weight: 600;
                font-style: italic !important;
            }

            .active {
                color: rgb(166, 105, 49);
            }
        }
    }

    ._mob_menu_c {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-right: 16px;

        .logo {
            height: $logo_height;
            width: max-content;
            margin-left: 16px;

            .logo_img {
                height: 100%;
                object-fit: contain;
            }
        }

        ._mob_menu_b {
            height: $m_button_height;
            width: $m_button_height;
            cursor: pointer;
            position: relative;

            .p1 {
                width: $m_button_height;
                height: $m_button_height/6;
                background-color: #616161;

                position: absolute;
                left: 0;
                top: 0;

                -webkit-transition: all .2s;
                -moz-transition: all .2s;
                transition: all .2s;
            }

            .p2 {
                width: $m_button_height;
                height: $m_button_height/6;
                background-color: #616161;

                position: absolute;
                left: 0;
                top: $m_button_height*5/12;

                -webkit-transition: all .2s;
                -moz-transition: all .2s;
                transition: all .2s;
            }

            .p3 {
                width: $m_button_height;
                height: $m_button_height/6;
                background-color: #616161;

                position: absolute;
                left: 0;
                bottom: 0;

                -webkit-transition: all .2s;
                -moz-transition: all .2s;
                transition: all .2s;
            }
        }

        ._mob_menu_b-active {
            height: $m_button_height;
            width: $m_button_height;
            cursor: pointer;
            position: relative;

            .p1 {
                width: $m_button_height*1.414;
                height: $m_button_height/6;
                background-color: #616161;
                transform: rotateZ(45deg);

                position: absolute;
                left: -$m_button_height*2.5/12;
                top: $m_button_height*5/12;

                -webkit-transition: all .2s;
                -moz-transition: all .2s;
                transition: all .2s;
            }

            .p2 {
                width: $m_button_height;
                height: $m_button_height/6;
                background-color: #616161;
                opacity: 0;

                position: absolute;
                left: 0;
                top: $m_button_height*5/12;

                -webkit-transition: all .2s;
                -moz-transition: all .2s;
                transition: all .2s;
            }

            .p3 {
                width: $m_button_height*1.414;
                height: $m_button_height/6;
                background-color: #616161;
                transform: rotateZ(-45deg);

                position: absolute;
                left: -$m_button_height*2.5/12;
                bottom: $m_button_height*5/12;

                -webkit-transition: all .2s;
                -moz-transition: all .2s;
                transition: all .2s;
            }
        }
    }
}

._mob_menu_block {
    width: 100%;
    height: calc(100vh - $bar_height);
    position: fixed;
    left: 0;
    top: $bar_height;
    background-color: #fff;
    pointer-events: all;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;

    ._menu_link {
        color: #616161;
        text-decoration: none;
        font-size: 36px;
        font-weight: 600;
        margin: 32px 16px 0 0;
        font-style: italic !important;
    }

    ._menu_link_lang_mob {
        color: #616161;
        text-decoration: none;
        font-size: 36px;
        font-weight: 600;
        margin: 32px 16px 0 0;
        font-style: italic !important;
        cursor: pointer;
    }

    .active {
        color: rgb(166, 105, 49);
    }
}
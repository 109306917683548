@import "./StyleConstants.scss";

.Service__root {
    width: 100%;
    min-height: calc(100vh - $bar_height);
    margin-top: $bar_height;
    
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .list {
        width: 100%;
        height: max-content;
        display: grid;
        grid-template-columns: 1fr 5fr 1fr;
        grid-auto-rows: max-content;
        margin: 32px;

        .item {
            width: 100%;
            display: grid;
            grid-template-columns: 2fr 5fr;
            gap: 5vw;
        }

        ._service_img_c {
            width: 100%;
            aspect-ratio: 1.5;
            background-color: #aaa;
            border: 0px;
            border-right: 8px solid rgb(166, 105, 49);
            display: flex;
            overflow: hidden;
        }

        ._service_img {
            width: 100%;
            object-fit: cover;
        }

        ._service_desc_c {
            width: 100%;
            height: max-content;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }
}

@media screen and (max-width: 1200px) {
    .Service__root {
        .list {
            grid-template-columns: .5fr 5fr .5fr;

            .item {
                grid-template-columns: 2fr 3fr;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .Service__root {
        .list {
            grid-template-columns: .1fr 1fr .1fr;

            .item {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
            }

            ._service_img_c {
                border-right: 0px;
                border-bottom: 8px solid rgb(166, 105, 49);
                aspect-ratio: 1.5;
            }
        }
    }
}
@import "./StyleConstants.scss";

.Home__root {
    width: 100%;
    min-height: calc(100vh - $bar_height);
    height: max-content;
    margin-top: $bar_height;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.slide_container1 {
    width: 100%;
    height: 70vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: #f0f0f0;//#aaa;
    overflow: hidden;
    position: relative;

    .company_name {
        color: #fff;
        font-size: 70px;
    }

    .company_subname {
        color: #00b4d8;
        font-size: 30px;
    }

    .home_logo_img {
        height: 50%;
        object-fit: contain;
        z-index: 1;
    }

    .home_bg_img {
        position: absolute;
        width: 100%;
        height: 70vh;
        object-fit: cover;
        opacity: 0.4;
    }

    -webkit-box-shadow: inset 0px -12px 50px 7px rgba(34, 60, 80, 0.2); 
    -moz-box-shadow: inset 0px -12px 50px 7px rgba(34, 60, 80, 0.2); 
    box-shadow: inset 0px -12px 50px 7px rgba(34, 60, 80, 0.2);
}

.slide_container2 {
    width: 100%;
    height: max-content;
    margin: 32px;

    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
    grid-auto-rows: max-content;

    .a_title {
        color: #A66931 !important;
    }
}

._img_container {
    width: 100%;
    aspect-ratio: 1.5;
    background-color: #aaa;
    border: 0;
    overflow: hidden;
}

._description_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.slide_container3 {
    width: 100%;
    height: max-content;
    margin: 32px;

    display: grid;
    grid-template-columns: 1fr 5fr 1fr;
    grid-auto-rows: max-content;
    
    .a_title {
        color: rgb(166, 105, 49) !important;
    }
}

.slide_item2 {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-auto-rows: max-content;
    width: 100%;
    gap: 5vw;
}

.slide_item3 {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-auto-rows: max-content;
    width: 100%;
    gap: 5vw;
}

@media screen and (max-width: 1200px) {
    .slide_container2 {
        grid-template-columns: .5fr 5fr .5fr;
    }

    .slide_container3 {
        grid-template-columns: .5fr 5fr .5fr;
    }

    .slide_item2 {
        display: grid;
        grid-template-columns: 2fr 2fr;
        grid-auto-rows: max-content;
        width: 100%;
        gap: 5vw;
    }

    .slide_item3 {
        display: grid;
        grid-template-columns: 2fr 2fr;
        grid-auto-rows: max-content;
        width: 100%;
        gap: 5vw;
    }
}

@media screen and (max-width: 800px) {
    .slide_container2 {
        grid-template-columns: .1fr 1fr .1fr;
    }

    .slide_container3 {
        grid-template-columns: .1fr 1fr .1fr;
    }

    .slide_item2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    .slide_item3 {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: flex-start;
    }

    .home_logo_img {
        width: 75%;
        object-fit: contain;
    }
}

.slide_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
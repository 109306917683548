@import "./StyleConstants.scss";

.Footer__root {
    width: 100%;
    height: $footer_height_pc;
    background-color: #eee;
    margin-top: 32px;

    //-webkit-box-shadow: 0px -12px 50px 7px rgba(34, 60, 80, 0.2); 
    //-moz-box-shadow: 0px -12px 50px 7px rgba(34, 60, 80, 0.2); 
    //box-shadow: 0px -12px 50px 7px rgba(34, 60, 80, 0.2);

    display: flex;
    align-items: center;
    justify-content: center;

    .grid {
        width: 90%;
        height: max-content;

        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-auto-rows: max-content;
        align-items: center;

        p {
            color: #616161;
            width: max-content;
        }

        a {
            color: #616161;
            text-decoration: none;
            width: max-content;
        }

        a:hover {
            color: rgb(166, 105, 49);
        }

        .center {
            text-align: center;
        }

        .end {
            text-align: right;
        }
    }
}

@media screen and (max-width: 800px) {
    .Footer__root {
        height: $footer_height_mob;

        .grid {
            width: 80%;
            gap: 10px;
            grid-template-columns: 1fr 1fr;
        }

        p {
            text-align: start;
        }

        .center {text-align: start !important;}
        .end {text-align: start !important;}
    }
}
$bar_height: 70px;
$logo_height: 60px;
$m_button_height: 40px;

$footer_height_pc: 100px;
$footer_height_mob: 140px;

.p_title {
    font-size: 60px;
    color: rgb(166, 105, 49);//rgb(56, 46, 42);//#b8875a;
    margin: 32px 0 8px 0;
    font-weight: 600;
    text-align: center;
}

.p_subtitle {
    font-size: 20px;
    text-align: center;
    color: #161616;
    margin: 0 0 24px 0;
    max-width: 50%;
}

.a_title {
    font-size: 30px;
    color: rgb(56, 46, 42);//rgb(166, 105, 49);
    font-weight: 600;
}

.a_content {
    font-size: 18px;
    color: #000;
    max-width: 100%;
    margin-top: 16px;
}

@media screen and (max-width: 800px) {
    .p_subtitle {
        max-width: 75%;
    }

    .p_title {
        font-size: 40px;
        max-width: 90%;
    }
}
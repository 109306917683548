@import "./StyleConstants.scss";

.Contacts__root {
    width: 100%;
    min-height: calc(100vh - $bar_height);
    height: max-content;
    margin-top: $bar_height;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .Contacts__wrapper {
        width: 100%;
        min-height: calc(100vh - $bar_height - $footer_height_pc - 32px);
        min-height: calc(100vh - $bar_height);
        height: max-content;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .Contacts__logo {
            width: 35%;
            object-fit: contain;
        }

        .Contacts__items-wrap {
            width: max-content;
            max-width: 80vw;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .Contact__container {
                width: max-content;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                margin-top: 16px;
    
                .Contact__icon {
                    width: 30px;
                    height: 30px;
                    object-fit: contain;
                    margin-right: 16px;
                }
    
                .Contact__content {
                    font-size: 14px;
                    font-weight: 600;
                    text-align: left;
                    max-width: calc(80vw - 46px);
                }
            }
        }
    }
}

.Contact__link {
    color: #000;
    text-decoration: none;
}

.Contact__link:hover {
    color: rgb(166, 105, 49);
}

@media screen and (max-width: 800px) {
    .Contacts__root {
        .Contacts__wrapper {
            min-height: calc(100vh - $bar_height - 32px);

            .Contacts__logo {
                width: 70%;
            }
        }
    }
}